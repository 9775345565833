import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login/index'),
      meta: { title: '登录', bodyBackground: '#fbfbfb' },
      beforeEnter: (to, from, next) => {
        if (!sessionStorage.getItem('url')) {
          sessionStorage.setItem('url', from.fullPath)
        }
        next()
      }
    },
    {
      path: '/',
      component: Layout,
      redirect: '/index',
      children: [
        {
          path: 'index',
          component: () => import('@/views/dashboard/index'),
          name: 'Dashboard',
          meta: { title: '首页' }
        }
      ]
    },
    {
      path: '/paper',
      component: Layout,
      children: [
        {
          path: 'index',
          component: () => import('@/views/paper/index'),
          name: 'PaperIndex',
          meta: { title: '试卷中心' }
        }
      ],
      meta: {
        needLogin: true
      }
    },
    {
      path: '/record',
      component: Layout,
      children: [
        {
          path: 'index',
          component: () => import('@/views/record/index'),
          name: 'RecordIndex',
          meta: { title: '考试记录' }
        }
      ],
      meta: {
        needLogin: true
      }
    },
    {
      path: '/question',
      component: Layout,
      children: [
        {
          path: 'index',
          component: () => import('@/views/question-error/index'),
          name: 'QuestionErrorIndex',
          meta: { title: '错题本' }
        }
      ],
      meta: {
        needLogin: true
      }
    },
    {
      path: '/user',
      component: Layout,
      children: [
        {
          path: 'index',
          component: () => import('@/views/user-info/index'),
          name: 'UserInfo',
          meta: { title: '个人中心' }
        },
        {
          path: 'message',
          component: () => import('@/views/user-info/message'),
          name: 'UserMessage',
          meta: { title: '消息中心' }
        }
      ],
      meta: {
        needLogin: true
      }
    },
    {
      path: '/practice',
      component: Layout,
      children: [
        {
          path: 'index',
          component: () => import('@/views/practice/index'),
          name: 'Practice',
          meta: { title: '练习中心' }
        },
        {
          path: 'paper',
          component: () => import('@/views/practice/paper'),
          name: 'Practice',
          meta: { title: '试卷练习' }
        },
        {
          path: 'topic',
          component: () => import('@/views/practice/topic'),
          name: 'Practice',
          meta: { title: '专项练习' }
        }
      ],
      meta: {
        needLogin: true
      }
    },
    {
      path: '/exam/do',
      name: 'ExamPaperDo',
      component: () => import('@/views/exam/paper/do'),
      meta: {
        title: '试卷答题',
        needLogin: true
      }
    },
    {
      path: '/exam/read',
      name: 'ExamPaperRead',
      component: () => import('@/views/exam/paper/read'),
      meta: {
        title: '试卷查看',
        needLogin: true
      }
    },
    {
      path: '*',
      component: () => import('@/views/error-page/404'),
      meta: { title: '404' }
    }
  ]
})


export default router
