<template>
  <el-container>
    <el-header height="61" class="student-header">
      <div class="head-user">
        <el-dropdown trigger="click" placement="bottom">
           <span>
             <el-avatar v-if="userId" class="el-dropdown-avatar" size="medium"
                        :src="image"></el-avatar>
             <el-avatar v-else class="el-dropdown-avatar" size="medium"
                        :src="require('@/assets/avatar.png')"></el-avatar>
           </span>
          <el-dropdown-menu slot="dropdown" v-if="userId">
            <el-dropdown-item @click.native="skip">个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="skip2()" v-if="messageCount>0">
              <el-badge :value="messageCount" v-if="messageCount>0">消息</el-badge>
            </el-dropdown-item>
            <el-dropdown-item @click.native="skip2()" v-else>消息</el-dropdown-item>
            <el-dropdown-item @click.native="logout" divided>退出</el-dropdown-item>
          </el-dropdown-menu>
          <el-dropdown-menu slot="dropdown" v-else>
            <el-dropdown-item @click.native="skip3()">登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-menu class="el-menu-title" mode="horizontal" :default-active="this.$route.path" :router="true">
        <el-menu-item index="/index">首页</el-menu-item>
        <el-menu-item index="/paper/index">试卷中心</el-menu-item>
        <el-menu-item index="/record/index">考试记录</el-menu-item>
        <el-menu-item index="/practice/index">习题练习</el-menu-item>
        <el-menu-item index="/question/index">错题本</el-menu-item>
      </el-menu>
      <div>
        <!--        <img src="@/assets/logo.png" height="56"/>-->
        <div class="logo">在线考试系统</div>
      </div>
    </el-header>
    <el-main class="student-main">
      <router-view/>
    </el-main>
    <el-footer height="340" class="student-footer">
      <div class="container">
        <div class="footer-main">
          <h4>友情链接</h4>
          <a href="https://spring.io/" target="_blank" class="footer-main-link">Spring</a>
          <a href="https://cn.vuejs.org/" target="_blank" class="footer-main-link">Vue</a>
          <a href="https://element.eleme.io/" target="_blank" class="footer-main-link">Element</a>
          <a href="https://www.postgresql.org/" target="_blank" class="footer-main-link">Postgresql</a>
          <a href="https://redis.io/" target="_blank" class="footer-main-link">Redis</a>
          <a href="http://undertow.io/" target="_blank" class="footer-main-link">Undertow</a>
          <a href="http://nginx.org/" target="_blank" class="footer-main-link">Nginx</a>
        </div>
        <div class="footer-main">
          <h4>博客</h4>
          <a href="#" target="_blank" class="footer-main-link">在线讨论</a>
          <a href="https://www.google.com/" target="_blank" class="footer-main-link">Google</a>
          <a href="https://www.oschina.net/" target="_blank" class="footer-main-link">Oschina</a>
          <a href="https://www.infoq.cn/" target="_blank" class="footer-main-link">Infoq</a>
          <a href="https://www.iconfont.cn/" target="_blank" class="footer-main-link">Iconfont</a>
        </div>
        <div class="footer-social">
          <p class="footer-social-title">开源社区</p>
          <a href="https://github.com/alvis-u/exam" target="_blank">
            <svg-icon icon-class="social-github" style="width: 32px;height: 32px;"/>
          </a>
          <a href="https://gitee.com/alvis-yu/exam" target="_blank">
            <svg-icon icon-class="gitee" style="width: 32px;height: 32px;"/>
          </a>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { logout_info } from '@/store/utils'
import { examGet } from '@/api/base/exam'
import Cookies from 'js-cookie'
import { getCookie } from '@/store/utils'

export default {
  name: 'Layout',
  data () {
    return {
      userId: '',
      image: '',
      defaultUrl: '/index',
      messageCount: 0
    }
  },
  created () {
    // this.defaultUrl = this.$route.path
    this.init()
  },
  methods: {
    init () {
      this.userId = getCookie('user_id')
      this.image = getCookie('user_image')
      if (this.userId) {
        this.getUserMessageCount()
      }
    },
    getUserMessageCount () {
      let params = {
        userId: getCookie('user_id')
      }
      examGet('/message/userMessage', params).then(res => {
        if (res.status == 0) {
          this.messageCount = res.data
        } else {
          this.messageCount = 0
        }
      }).catch(err => {
        console.error('网络链接错误！' + err)
      })
    },
    skip () {
      this.$router.push({ path: '/user/index' })
    },
    skip2 () {
      this.$router.push({ path: '/user/message' })
    },
    skip3 () {
      this.$router.push({ path: '/login' })
    },
    logout () {
      logout_info()
      this.userId = false
      this.$message({
        message: '退出登录',
        type: 'success'
      })
      this.$router.push({ path: '/' })
    },
  }
}
</script>

<style lang="scss" scoped>
.logo {
  margin: 15px;
  font-size: 25px;
  color: cadetblue;
  font-weight: bold;
}
</style>
