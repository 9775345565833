import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'normalize.css/normalize.css'
import Element,{MessageBox, Message}  from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css
import './icons' // icon
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

import Cookies from 'js-cookie'

import VueQuillEditor from 'vue-quill-editor'

// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueCodeMirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';

Vue.use(VueCodeMirror)
Vue.use(VueQuillEditor)
import vgl from 'vue-golden-layout'
import 'golden-layout/src/css/goldenlayout-dark-theme.css'


import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import { getCookie, setCookie, setCookieTime } from '@/store/utils'
Vue.use(mavonEditor)
Vue.use(vgl);

Vue.use(Element, {
  size: 'medium' // set element-ui default size
})

Vue.config.productionTip = false

Vue.prototype.$axios = axios

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  if (to.meta.title !== undefined) {
    document.title = to.meta.title
  } else {
    document.title = '\u200E'
  }

  if (to.meta.bodyBackground !== undefined) {
    document.querySelector('body').setAttribute('style', 'background: ' + to.meta.bodyBackground)
  } else {
    document.querySelector('body').removeAttribute('style')
  }

  let isLogin = Cookies.get("user_id")
  console.log(isLogin)
  let needLogin = to.matched.some(match => match.meta.needLogin)
  if (needLogin) {
    //需要登录
    if (isLogin) {
      //登录过了
      next();
    } else {
      router.push({path:"/login"})
    }
  } else {
    //不需要登录
    next()
  }
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})


axios.interceptors.request.use(
  config => {
    console.log(getCookie("CERTIFICATE"))
    if (config.url.toString().startsWith("/oauth2/")
      || config.url.toString().startsWith("/login")
      || config.url.toString().startsWith("/judge/submissions")
      || config.url.toString().startsWith("/submissions")) {
      return config;
    }
    if (null != getCookie("CERTIFICATE")
      && "" !=getCookie("CERTIFICATE")) {
      config.headers.Authorization = "Bearer " + getCookie("CERTIFICATE");
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)


let refreshing = false,// 正在刷新标识，避免重复刷新
  waitQueue = [] // 请求等待队列

axios.interceptors.response.use(
  response => {
    // console.log(response)
    return response;
  },
  error => {
    if (error.response) {
      console.log(error.response)
      switch (error.response.status) {
        case 401:
          if (error.response.config.url.toString().startsWith("/oauth2/")) {
            return Promise.reject(error.response.data)
          }
          // access_token过期 使用refresh_token刷新换取access_token
          let config = error.response.config
          if (refreshing === false) {
            const refreshToken = {
              "refresh_token": getCookie("EXCHANGE_CERTIFICATE")
            }
            console.log(refreshToken);
            refreshing = true;
            return axios.post('/oauth2/refresh_user', refreshToken).then((token) => {
              console.log(token)
              setCookieTime("CERTIFICATE",token.data.access_token, token.data.expire);
              setCookie("EXCHANGE_CERTIFICATE",token.data.refresh_token, 7);
              config.headers['Authorization'] = 'Bearer ' + getCookie("CERTIFICATE")
              waitQueue.forEach(callback => callback(token)) // 已刷新token，所有队列中的请求重试
              waitQueue = []
              return axios(config)
            }).catch(() => {
              // refresh_token也过期，直接跳转登录页面重新登录
              MessageBox.confirm('无登录信息，请重新登录', '确认退出', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning',

              }).then(() => {
                location.href = "/"
              })
            }).finally(() => {
              refreshing = false
            })
          }
          else {
            return new Promise((resolve) => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              waitQueue.push((token) => {
                config.headers['Authorization'] = token
                resolve(axios(config))
              })
            })
          }
      }
    }
    return Promise.reject(error)
  }
)



Vue.prototype.$$router = router

new Vue({
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app')
