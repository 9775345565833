import axios from '@/plugins/request'

require('../config.js')

let baseApi = global.gConfig.baseAPI.exam

export const examGet = (api, param) => {
  param = param || {};
  return axios.get(`${baseApi}${api}`, {params: param}).then(res => res.data).catch(err => err);
};

export const examPost = (api, params) => {
  params = params || {};
  return axios.post(`${baseApi}${api}`, params).then(res => res.data).catch(err => err);
};

export const examPut = (api, params) => {
  params = params || {};
  return axios.put(`${baseApi}${api}`, params).then(res => res.data).catch(err => err);
};
