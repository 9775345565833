import Cookies from 'js-cookie'

const Token = 'CERTIFICATE'
const Refresh_Token = 'REFRESH_CERTIFICATE'

const user_info_session = function (user) {
}

export const setCookieTime = function (cname, value, time) {
  let d = new Date()
  d.setTime(d.getTime() + (time * 1000))
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + value + ';' + expires + ';path=/'
}

const user_info_cookie = function (user, day) {
  Cookies.set('user_id', user.userId, { expires: day })
  Cookies.set('user_name', user.userNickname, { expires: day })
  Cookies.set('user_image', user.userImage, { expires: day })
  Cookies.set('enterprise_id', user.enterpriseId, { expires: day })
  Cookies.set('enterprise_name', user.enterpriseName, { expires: day })
  Cookies.set('CERTIFICATE_ID', user.certificateId, { expires: day })
}

export const login_info = function (user, day) {
  user_info_session(user)
  user_info_cookie(user, day)
}

export const token_info = function (token, day) {
  Cookies.set('CERTIFICATE', token.access_token, { expires: day })
  Cookies.set('REFRESH_CERTIFICATE', token.refresh_token, { expires: day })
}

export const logout_info = function () {
  Cookies.remove('user_id')
  Cookies.remove('user_image')
  Cookies.remove('user_name')
  Cookies.remove('enterprise_id')
  Cookies.remove('enterprise_name')
  Cookies.remove('CERTIFICATE_ID')
  Cookies.remove('CERTIFICATE')
  Cookies.remove('REFRESH_CERTIFICATE')
}

export const user_login_info = function (data, day, time) {
  setCookieTime('CERTIFICATE', data.CERTIFICATE, time)
  Cookies.set('REFRESH_CERTIFICATE', data.REFRESH_CERTIFICATE, { expires: day })
  Cookies.set('CERTIFICATE_ID', data.CERTIFICATE_ID, { expires: day })
}

export const getCookie = function (name) {
  return Cookies.get(name)
}

export const getToken = function () {
  return Cookies.get(Token)
}

export const getRefreshToken = function () {
  return Cookies.get(Refresh_Token)
}

export const setCookie = function (name, value, day) {
  Cookies.set(name, value, { expires: day })
}


