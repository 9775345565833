import axios from 'axios'
import NProgress from 'nprogress'
import { Loading, MessageBox, Message } from 'element-ui'
import { getToken, getRefreshToken, setCookieTime, setCookie } from '@/store/utils'

const server = axios.create({
  timeout: 60 * 1000,
  withCredentials: false
})

let global = true
let ve_loading
let loadingCount = 0

server.interceptors.request.use(config => {
    // do something before request is sent
    NProgress.done()

    NProgress.start()
    ve_loading = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0,0,0,0.1)',
    })

    if (config.url.toString() === ('/api/user/admin/admin_login')) {
      console.log(config)
      return config
    }
    loadingCount++
    //*请求头添加token
    if (null != getToken() && '' !== getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    console.log(config)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

let refreshing = false,// 正在刷新标识，避免重复刷新
  waitQueue = [] // 请求等待队列

server.interceptors.response.use(response => {
    const res = response.data

    if (response.status !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }

    console.log(response)
    loadingCount--
    if (loadingCount <= 0) {
      NProgress.done()
      ve_loading.close()
    }
    return response

  },
  error => {
    console.log('err' + error) // for debug
    loadingCount--
    if (loadingCount <= 0) {
      NProgress.done()
      ve_loading.close()
    }
    console.log(error.response)
    if (error && error.response) {
      let message
      switch (error.response.status) {
        case 400:
          message = '请求错误'
          break
        case 401:
          // access_token过期 使用refresh_token刷新换取access_token
          // eslint-disable-next-line no-case-declarations
          let config = error.response.config
          if (refreshing === false) {
            const refreshToken = {
              'refresh_token': getRefreshToken()
            }
            refreshing = true
            return axios.post('/oauth2/refresh_user', refreshToken).then((token) => {
              setCookieTime('CERTIFICATE', token.data.access_token, token.data.expire)
              setCookie('REFRESH_CERTIFICATE', token.data.refresh_token, 7)
              console.log(getToken())
              config.headers['Authorization'] = 'Bearer ' + getToken()
              waitQueue.forEach(callback => callback(token)) // 已刷新token，所有队列中的请求重试
              waitQueue = []
              return server(config)
            }).catch(() => {
              // refresh_token也过期，直接跳转登录页面重新登录
              MessageBox.confirm('当前页面已失效，请重新登录', '确认退出', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                location.href="/login"
              }).catch(() => {
              })
            }).finally(() => {
              refreshing = false
            })
          }
          break
        case 403:
          message = '没有权限，拒绝访问'
          break
        case 404:
          message = `请求地址出错`
          break
        case 408:
          message = '请求超时'
          break
        case 500:
          message = '服务器内部错误'
          break
        case 501:
          message = '服务未实现'
          break
        case 502:
          message = '网关错误'
          break
        case 503:
          message = '服务不可用'
          break
        case 504:
          message = '网关超时'
          break
        case 505:
          message = 'HTTP版本不受支持'
          break
        default:
          break
      }
    }
    return Promise.reject(error)
  }
)

export default server
