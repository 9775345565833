const config = {
  'development': {
    'config_name': 'development',
    'baseAPI': {
      'user': '/api/user',
      'enterprise': '/api/sale',
      'judge': '/judge',
      'exam': '/api/exam'
    }
  },
  'product': {
    'config_name': 'product',
    'baseAPI': {
      'user': '/api/user',
      'enterprise': '/api/sale',
      'judge': '/judge',
      'exam': '/api/exam'
    }
  },
  'test': {
    'config_name': 'test',
    'baseAPI': {
      'user': '/api/user',
      'enterprise': '/api/sale',
      'judge': '/judge',
      'exam': '/api/exam'
    }
  }
}

if (process.env.NODE_ENV === 'development') {
  global.gConfig = config.development
}
if (process.env.NODE_ENV === 'production') {
  global.gConfig = config.product
}
if (process.env.NODE_ENV === 'test') {
  global.gConfig = config.test
}




